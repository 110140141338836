<template>
  <div class="bank-transaction-modal">
    <vs-popup class="bank-transaction-modal--popup" title="Banktransaktion bearbeiten" @close="onClose" :active="visible">
      <div v-show="dataTransaction">
        <div class="vx-row mb-2 p-4">
          <div class="vx-col w-full mb-0">

            <!-- Bank Account Select -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Bankkonto:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <mg-select v-model="dataTransaction.bank_account_id" :options="bankAccounts" track-by="id" label="label" placeholder="Wählen Sie ein Bankkonto" name="bank_account_id" class="mb-2 w-full" v-validate="'required'"></mg-select>
                <span class="text-danger text-sm" v-if="errors.has('bank_account_id')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>

            <!-- Booking Date -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Buchungsdatum:</label>
              </vs-col>
              <vs-col  vs-justify="left" vs-align="left" vs-w="9">

                <datepicker v-model="dataTransaction.booking_date" placeholder="Buchungsdatum" class="w-full" v-validate="'required'"
                            format="yyyy-MM-dd"></datepicker>
                <div>
                    <small><span class="link" @click="setDateYesterday">Gestern</span>
                    |
                    <span class="link" @click.prevent="setDateToday">Heute</span>
                  </small>
                </div>
                <span class="text-danger text-sm" v-if="errors.has('booking_date')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>


            <!-- Client Select -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Kunde:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <mg-select v-model="dataTransaction.client_id" :options="clients" track-by="id" label="company_name" placeholder="Wählen Sie einen Kunden" name="client_id" class="mb-2 w-full" v-validate="'required'"
                           @select="onSelectClient"
                           @remove="dataTransaction.client_id=null"
                ></mg-select>
                <span class="text-danger text-sm" v-if="errors.has('client_id')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>

            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Kunde:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <vs-input v-model="dataTransaction.recipient" placeholder="Name des Kunden" name="recipient" class="mb-2 w-full" v-validate="'required'"></vs-input>
                <span class="text-danger text-sm" v-if="errors.has('recipient')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>


            <!-- Reference Text (Textarea) -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Buchungstext:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <vs-textarea v-model="dataTransaction.reference_text" placeholder="Buchungstext" name="reference_text" class="mb-2 w-full" v-validate="'required'"></vs-textarea>
                <span class="text-danger text-sm" v-if="errors.has('reference_text')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>

            <!-- Sum -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Betrag:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                <vs-input v-model="dataTransaction.sum" placeholder="Summe" name="sum" class="mb-2 w-full" v-validate="'required'"></vs-input>
                <span class="text-danger text-sm" v-if="errors.has('sum')">Dieses Feld ist ein Pflichtfeld</span>
              </vs-col>
            </vs-row>

            <!-- Booking Type (Options: + or -) -->
            <vs-row class="mt-4">
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                <label>Buchungstyp:</label>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">


                <ul class="leftx">
                  <li>
                    <vs-radio color="danger" v-model="dataTransaction.booking_type" vs-value="-">Abbuchung</vs-radio>
                  </li>
                  <li>
                    <vs-radio color="success" v-model="dataTransaction.booking_type" vs-value="+">Zubuchung</vs-radio>
                  </li>
                </ul>

              </vs-col>
            </vs-row>

          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="flex p-2 justify-end bg-gray-200 rounded-b">
        <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
        <vs-button @click="saveTransaction" :disabled="!formValid">Speichern</vs-button>
      </div>
    </vs-popup>
  </div>
</template>


<script>
import ApiService from "../../api";
import MgSelect from "../../components/mg-select/MgSelect";
import { mapGetters } from "vuex";
import Datepicker from '@sum.cumo/vue-datepicker';
import moment from "moment/moment";

export default {
  name: "create-bank-transaction-modal",
  components: {
    MgSelect,
    Datepicker
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    bankTransaction: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataTransaction: {
        bank_account_id: null,
        booking_date: null,
        valuta_date: null,
        client_id: null,
        iban: null,
        bic: null,
        reference_text: null,
        sum: null,
        booking_type: "-"
      },
      bankAccounts: [],
      clients: []
    };
  },
  computed: {
    ...mapGetters(['userHasPermission', 'userId']),
    visible() {
      return this.active;
    },
    formValid() {
      return !this.errors.any();
    }
  },
  methods: {
    openModal() {
      this.fetchBankAccounts();
      this.fetchClients();
      if (this.bankTransaction) {
        // Populate the form fields if editing
        this.dataTransaction = { ...this.bankTransaction };
      } else {
        // Reset the form fields if creating a new transaction
        this.resetForm();
      }
    },

    resetForm() {
      this.dataTransaction = {
        bank_account_id: null,
        booking_date: null,
        valuta_date: null,
        client_id: null,
        iban: null,
        bic: null,
        reference_text: null,
        sum: null,
        booking_type: "-"
      };
    },
    fetchBankAccounts() {
      ApiService.get('bank-account').then(response => {
       // this.bankAccounts = response.data.result;
        this.bankAccounts = response.data.result.filter(account => account.type === 'cash');
      });
    },
    fetchClients() {
      ApiService.get('clients').then(response => {
        this.clients = response.data.result;
      });
    },
    saveTransaction() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const payload = { ...this.dataTransaction };

          if (this.bankTransaction && this.bankTransaction.id) {
            // Update existing transaction
            ApiService.put(`bank-transactions/${this.bankTransaction.id}`, payload)
                .then(response => {
                  if (response.data.status === "success") {
                    this.$vs.notify({
                      title: "Erfolgreich",
                      text: "Die Transaktion wurde erfolgreich aktualisiert.",
                      color: "success"
                    });
                    this.$emit('saved');
                    this.onClose();
                  } else {
                    this.$vs.notify({
                      title: "Fehler",
                      text: "Die Transaktion konnte nicht aktualisiert werden.",
                      color: "danger"
                    });
                  }
                })
                .catch(error => {
                  this.$vs.notify({
                    title: "Fehler",
                    text: error.message,
                    color: "danger"
                  });
                });
          } else {
            // Create new transaction
            ApiService.post('bank-transactions', payload)
                .then(response => {
                  if (response.data.status === "success") {
                    this.$vs.notify({
                      title: "Erfolgreich",
                      text: "Die Transaktion wurde erfolgreich gespeichert.",
                      color: "success"
                    });
                    this.$emit('saved');
                    this.onClose();
                  } else {
                    this.$vs.notify({
                      title: "Fehler",
                      text: "Die Transaktion konnte nicht gespeichert werden.",
                      color: "danger"
                    });
                  }
                })
                .catch(error => {
                  this.$vs.notify({
                    title: "Fehler",
                    text: error.message,
                    color: "danger"
                  });
                });
          }
        }
      });
    },
    onClose() {
      this.$emit('update:active', false);
      this.$emit('close');
    },
    setDateYesterday() {
      this.dataTransaction.booking_date = moment().subtract(1, 'day').format('YYYY-MM-DD');
    },
    setDateToday() {
      this.dataTransaction.booking_date = moment().format('YYYY-MM-DD');
    },
    onSelectClient(client) {
      this.dataTransaction.recipient = client.company_name;
    }
  },
  watch: {
    active(val) {
      if (val) {
        this.openModal();
      }
    },
    bankTransaction: {
      handler(newVal) {
        if (newVal) {
          // Populate the form with the existing transaction data for editing
          this.dataTransaction = { ...newVal };
        } else {
          // Reset form for creating a new transaction
          this.resetForm();
        }
      },
      immediate: true
    }
  }
};
</script>


<style lang="scss">
    .billing-transfer-modal--popup {
        .vs-popup {
            min-width: 700px;
            max-width: 100vw;
        }

        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;
        }
      .documentprice-badge {
        top: -8px;
        position: relative;
        font-size: 10px;
      }
    }
</style>
